import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@mui/material';

const Navbar = () => {

    const handleLogout = async () => {
        try {
            // Make a POST request to the specified endpoint
            const response = await axios.post('/logout', {
                // Add any data to be sent in the request body
            }, {
                withCredentials: true,
            });

            if (response.status === 200) {
                // Logout successful
                console.log('Logout successful');
                // Redirect to login page
                window.location.href = '/';
            } else {
                // Handle error response from server
                console.error('Failed to logout');
            }
        } catch (error) {
            // Handle network error or other exceptions
            console.error('Error logging out:', error);
        }
    };


    return (
        <div className="bg-gray-800 text-white h-screen w-1/5 flex flex-col justify-between">
            <div className="p-4">
                {/* <h2 className="text-xl font-bold">Sidebar</h2> */}
                <ul className="mt-4">
                    <li>
                        <Link to="/upload" className="block py-2 px-4 rounded hover:bg-gray-700">Upload</Link>
                    </li>
                    <li>
                        <Link to="/tasks" className="block py-2 px-4 rounded hover:bg-gray-700">Tasks</Link>
                    </li>
                </ul>
            </div>
            <div className="p-4">
                <Button variant="contained" color="primary" onClick={handleLogout} style={{ marginLeft: '10px' }}>
                    Logout
                </Button>
            </div>
        </div>
    )

}

export default Navbar;