import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import Cookies from 'js-cookie';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

const Upload = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const fileInput = useRef();


    useEffect(() => {
        // Fetch data from API when component mounts
        if (Cookies.get("token") == null) {
            window.location.href = '/';
        } else {
            console.log("fetching data");
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try {
            // Replace 'your-api-endpoint' with the actual API endpoint to fetch data
            await axios.get(`/getExcelUploads`)
                .then((response) => {
                    console.log(response.data);
                    setData(response.data);
                }).catch((error) => {
                    console.log(error);
                    window.location.href = '/';
                }
                );
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error fetching data
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleUpload = async (e) => {
        // Implement upload logic here, e.g., show file input, send file to server, etc.
        e.preventDefault();


        try {

            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            // Assuming only one file is selected
            console.log(formData.entries().next().value);
            // Make a POST request to the specified endpoint


            const response = await axios.post('/uploadTest', { file }, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data for form data
                },
                withCredentials: true,
            });

            if (response.status === 200) {
                // File uploaded successfully
                console.log(response);
                console.log('File uploaded successfully');
                e.target.value = "";
                // Optionally, you can fetch the updated data after upload
                fetchData();
            } else {
                // Handle error response from server
                console.log(response);
                e.target.value = "";
                console.error('Failed to upload file');
            }
        } catch (error) {
            // Handle network error or other exceptions
            e.target.value = "";
            console.error('Error uploading file:', error);
        }
    };


    return (
        <div className='bg-gray-100 h-screen py-3 flex-1 w-full'>
            <div className='flex justify-end w-11/12 m-auto'>
                <Button variant="contained" color="primary" onClick={() => fileInput.current.click()}>
                    Upload
                    <input ref={fileInput} type="file" accept=".xlsx" className='hidden' onChange={handleUpload} />
                </Button>

            </div>
            <div className='w-11/12 m-auto mt-5 h-5/6 overflow-y-auto'>
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead className='bg-black'>
                            <TableRow>
                                <TableCell><p className='text-gray-100'>Filename</p></TableCell>
                                <TableCell><p className='text-gray-100'>Status</p></TableCell>
                                <TableCell><p className='text-gray-100'>Date Uploaded</p></TableCell>
                                <TableCell><p className='text-gray-100'>Total Uploaded</p></TableCell>
                                {/* Add more table headers as needed */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : data
                            ).map((item) => (
                                <TableRow key={item.id}>
                                    {/* <TableCell>{item.id}</TableCell> */}
                                    <TableCell>{item.filename}</TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell>{item.uploadedAt}</TableCell>
                                    <TableCell>{item.total}</TableCell>
                                    {/* Render more table cells for additional data */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default Upload;