import logo from './logo.svg';
import './App.css';
import LoginPage from './pages/login'
import Upload from './pages/upload'
import TaskPage from './pages/tasks';
import Navbar from './components/Navbar';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WithoutNavbar />}>
          <Route index element={<LoginPage />} />
        </Route>
        <Route path="/*" element={<WithNavbar />} />
      </Routes>
    </BrowserRouter>
  );
}

function WithNavbar() {
  return (
    <div className="flex w-full">
      <Navbar />
      <div className='w-full'>
        <Routes>
          <Route path="/upload" element={<Upload />} />
          <Route path="/tasks" element={<TaskPage />} />
        </Routes>
      </div>
    </div>
  );
}

function WithoutNavbar() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;
