import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { TextField, Button, Grid, Paper } from '@mui/material';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        // Add your login logic here
        await axios.post('/login', { username, password })
            .then((response) => {
                console.log(response.data);
                Cookies.set('token', response.data.data);
                //redirect to upload page
                window.location.href = '/upload';
            }
            ).catch((error) => {
                console.log(error);
                setError(error.response.data.error);
            }
            );


        console.log('Logging in with:', { username, password });
    };

    return (
        <div className='flex justify-center items-center flex-col bg-gray-100 h-screen'>

            <Paper className='p-4 rounded-3xl'>
                <h1 className='text-4xl font-bold mb-2'>Login</h1>
                <Grid container spacing={4} padding={2}>
                    <Grid item xs={12} >
                        <TextField
                            label='Username'
                            variant='outlined'
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Password'
                            variant='outlined'
                            type='password'
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    {error && (
                        <Grid item xs={12} className='flex justify-center'>
                            {/* <p style={{ color: 'red' }}>{error}</p> */}
                            <div className='p-2 bg-red-200 w-fit rounded-lg border-red-600 border-2'>
                                <p className='text-red-600 font-medium '>{error}</p>    
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={handleLogin}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default LoginPage;
